<template>
  <div class="container">
    <h1>This page cannot be found. <br />(๑•́ ᎔ ก̀๑)</h1>
  </div>
</template>

<style scoped>
.container h1 {
  font-size: 2.5rem;
  font-weight: 600;
  line-height: 60px;
  text-align: center;
}
</style>
